import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import colors from '../components/_colors.scss';

const Ventures = styled.main``;

const Area = styled.section`
	display: flex;
	flex-direction: column;
	position: relative;
	&:first-of-type {
		border-bottom: 1px solid;
	}

	@media (min-width: 1024px) {
		flex-direction: row;
		min-height: calc(100vh - 226px);
	}
`;

const Section = styled.section`
	&.title {
		padding: 20px;
	}
	&.list {
		border-top: 1px solid;
		display: flex;
		flex-wrap: wrap;
	}
	@media (min-width: 1024px) {
		width: 50%;

		display: inline-block;

		&.title {
			padding: 100px 50px;
			position: sticky;
			top: 115px;
			height: calc(100vh - 226px);
		}

		&.list {
			border-top: none;
			border-left: 1px solid;
		}
	}
`;

const CompanyLink = styled.a`
	color: inherit;
	@media (min-width: 1024px) {
	}
`;

const PageTitle = styled.h2`
	font-size: 24px;

	@media (min-width: 1024px) {
		font-size: 2.6666666667em;
	}
`;

const Company = styled.article`
	width: 100%;
	border-bottom: 1px solid;

	&:hover {
		header {
			cursor: pointer;
			background-color: ${colors.navy};

			img {
				filter: brightness(0) invert(1);
			}
		}
	}

	@media (min-width: 1024px) {
		width: 50%;
		margin-bottom: -1px;

		&:nth-of-type(2n + 1) {
			border-right: 1px solid;
		}
	}
`;

const CompanyHeader = styled.header`
	padding: 30px 20px;
	cursor: pointer;
	border-bottom: 1px solid;
	display: flex;
	align-items: center;

	img {
		transition: filter 0.25s;
	}

	@media (min-width: 1024px) {
		padding: 30px 25px;
		height: 100px;
		transition: background-color 0.25s;
	}
`;

const CompanyLogo = styled.img`
	max-height: 36px;
	max-width: 175px;
	vertical-align: middle;
	object-fit: contain;
	@media (min-width: 1024px) {
		max-height: 50px;
		max-width: 250px;
		transition: filter 0.25s;
	}
`;

const Description = styled.p`
	padding: 20px;

	@media (min-width: 1024px) {
		padding: 30px 25px 50px;
		max-width: 75ch;
	}
`;

const VenturesPage = () => {
	const companiesData = useStaticQuery(graphql`
		{
			allSanityCompany {
				nodes {
					url
					title
					logo {
						asset {
							url
						}
					}
					description
				}
			}
			allSanityVentures {
				nodes {
					venturesList {
						url
						title
						logo {
							asset {
								url
							}
						}
						description
					}
					nonProfits {
						url
						title
						logo {
							asset {
								url
							}
						}
						description
					}
				}
			}
		}
	`);
	const [companies] = useState(companiesData.allSanityCompany.nodes);
	const [ventures] = useState(companiesData.allSanityVentures.nodes[0].venturesList);
	const [nonProfits] = useState(companiesData.allSanityVentures.nodes[0].nonProfits);

	return (
		<React.Fragment>
			<SEO title='Ventures | Marquise Stillwell' />
			{/* <Reveal > */}
			<Ventures>
				<Area>
					<Section className='title'>
						<PageTitle>Ventures</PageTitle>
					</Section>
					<Section className='list'>
						{ventures.length > 0 && (
							<React.Fragment>
								{ventures.map((company, index) => (
									<Company key={index}>
										<CompanyLink href={company.url} rel='noreferrer noopener' target='_blank'>
											<CompanyHeader>{company?.logo?.asset && <CompanyLogo src={company.logo.asset.url} alt={company.title} />}</CompanyHeader>
										</CompanyLink>

										<Description>{company.description}</Description>
									</Company>
								))}
							</React.Fragment>
						)}
					</Section>
				</Area>
				<Area>
					<Section className='title'>
						<PageTitle>Non-Profits</PageTitle>
					</Section>
					<Section className='list'>
						{nonProfits.length > 0 && (
							<React.Fragment>
								{nonProfits.map((company, index) => (
									<Company key={index}>
										<CompanyLink href={company.url} rel='noreferrer noopener' target='_blank'>
											<CompanyHeader>{company?.logo?.asset && <CompanyLogo src={company.logo.asset.url} alt={company.title} />}</CompanyHeader>
										</CompanyLink>

										<Description>{company.description}</Description>
									</Company>
								))}
							</React.Fragment>
						)}
					</Section>
				</Area>
			</Ventures>

			{/* </Reveal> */}
		</React.Fragment>
	)
};

export default VenturesPage;
